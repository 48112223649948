.add-org {
  width: 100%;
  height: 100%;
  transition: opacity 0.4s;
  z-index: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  &-hidden {
    visibility: hidden;
  }

  &__left-section {
    width: 48%;
    &__input {
      &:not(:first-child) {
        margin: 30px 0;
      }
      display: block;
    }
  }

  &__button-form {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    &-submit-btn {
      margin: 10px 0px 5px 0px;
      width: 376px;
    }
  }

  &__btn {
    position: relative;
    margin: 29px 0px 0px;
    width: 376px;

    &:disabled {
      filter: opacity(0.5);
      background-color: grey;
    }

    &-create {
      position: relative;
      width: 376px;
      margin-top: 25px;
      height: 42px;
    }

    &-last {
      margin: 15px 0px 0px;
    }

    &-loader {
      position: absolute;
      top: calc(50% - 8px);
      left: calc(50% - 8px);
      width: 16px;
      height: 16px;
      z-index: 9;
      animation: spinAnimation 1s linear infinite;

      @keyframes spinAnimation {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }

  &-confirm__modal__body {
    display: block;
    font-family: 'Nexa Regular';
    font-size: 14px;
    line-height: 18px;
    height: 163px;
    width: 364px;

    &.success {
     text-align: center;
      height: 113px;
    }

    &-bold {
      font-family: 'Nexa Bold';
      font-size: 14px;
      font-weight: 700;
      line-height: 18px;
    }
  }

  &__right-section {
    height: 443px;
    width: 48.5%;
    padding: 20px;
    margin: 8px 0px 0px 0px;
    background-color: #f0f2f9;
    border-radius: 10px;

    &__header {
      width: 102%;
      height: 42px;
      border-bottom: 1px solid #fff;
    }

    &__body {
      width: 103%;
      height: 360px;
      overflow-y: scroll;

      &-row {
        height: 50px;
        transition: background-color 0.2s;
        border-radius: 10px;

        &:hover {
          background-color: #fff;
        }

        &.with-border {
          border-bottom: 1px solid white;
        }

        &.disabled {
          height: 50px;
          cursor: default;
          &:hover {
            background-color: transparent;
          }
        }
      }

      &::-webkit-scrollbar {
        width: 4px;
        &-thumb {
          background: #29335c;
          border-radius: 10px;
          box-shadow: inset 0px 10px 0px 5px transparent;
        }
      }
      &::-moz-scrollbar {
        width: 4px;
        &-thumb {
          background: #29335c;
          border-radius: 10px;
          box-shadow: inset 0px 10px 0px 5px transparent;
        }
      }
      &::-ms-scrollbar {
        width: 4px;
        &-thumb {
          background: #29335c;
          border-radius: 10px;
          box-shadow: inset 0px 10px 0px 5px transparent;
        }
      }
    }
  }
}
